
<template>
    <el-container>
        <el-header height="80px">
            <header-control v-if="UserDetails !== null" :current-screen="Screen" :user-details="UserDetails"
                            @go-to-home="BackToScreen('Report Selection')" @commission-setting="CommissionSettingSelected"
                            @price-setting="LoadPage('Income Protection Price Setting')" @contact-us="ContactUsSelected"
                            @config-products="LoadPage('Products Configuration')"
                            @config-filters="LoadPage('Filters Configuration')"
                            @popup-message="PopupMessageSelected" />
        </el-header>
        <el-main style="position: absolute; top: 80px; bottom: 51.5px; left: 0; right: 0; padding: 0 0 0 0; background: #E0E0E0;">
            <div>
                <div v-if="Selection === null" class="maxWidth">
                    <loading-control :loading-message="'Loading'" />
                </div>
                <div v-else>
                    <div v-if="isLoading" class="maxWidth">
                        <loading-control :loading-message="'Generating results'" />
                    </div>

                    <div class="maxWidth">
                        <user-message v-if="isShowUserPopup" @close="isShowUserPopup = false" />
                    </div>

                    <div v-if="compareRouterScreens(Screen)">
                        <router-view :userDetails="UserDetails" :userSelection="Selection" :adverts="Adverts" :currentScreen="Screen" v-bind:template-set="TemplateSet" v-on:back="back"
                                     v-on:edit-customer="LoadPage('Add Customer')"
                                     v-on:policy-details-added="policyDetailsAdded"
                                     v-on:tool-selected="toolSelected" @tool-updated="toolUpdate"
                                     v-on:express-question-without-price-selected="LoadPage('Express Question Without Price')"
                                     v-on:express-question-selected="expressQuestionSelected"
                                     v-on:ip-express-question-selected="ipExpressQuestionSelected"
                                     v-on:ta-express-question-selected="taExpressQuestionSelected"
                                     v-on:fib-express-question-selected="fibExpressQuestionSelected"
                                     v-on:bp-compare-critical-illness-policy="bpCompareCriticalIllnessPolicy"
                                     v-on:bp-express-question-selected="bpExpressQuestionSelected"
                                     @info-verification="infoVerification"
                                     @ci-Policy-Details-Added="ciPolicyDetailsAdded" @load-tool-selection="LoadPage('Tool Selection')" v-on:custom-route-selected="LoadPage('Analysis')"
                                     @refine-selection="RefineSelection" @amend-quality-analysis="AmendQualityAnalysis" v-on:edit-policy="RefineSelection()"
                                     @find-your-legacy-policy="FindYourLegacyPolicySelected"
                                     @analysis-selected="loadAnalysis"
                                     @category-selection-skipped="categorySelectionSkipped()" v-on:category-selection-completed="categorySelectionCompleted()"
                                     v-on:backtopolicy-selected="BackToPolicy()" :current-category="CurrentFeatureCategory" :is-last="IsLast()"
                                     v-on:feature-selection-completed="featureSelectionCompleted"
                                     @feature-selection-skipped="featureSelectionSkipped"
                                     @datatable-selection-completed="LoadDataTables">
                        </router-view>
                    </div>
                    <div class="maxWidth">
                        <contact-us v-if="Screen == 'Contact Us' && UserDetails !== null" v-on:back="back"
                                    v-bind:user-details="UserDetails" />
                    </div>

                    <div class="maxWidth">
                        <find-your-legacy-policy v-if="Screen == 'Find Your Legacy Policy'" v-on:back="back"
                                                 v-bind:user-details="UserDetails" v-bind:missing-legacy-policy="MissingLegacyPolicy" />
                    </div>

                    <div class="maxWidth">
                        <integration-modal v-if="UserDetails !== null && ((!UserDetails.hasSetNoPricing) && (!UserDetails.integrations?.length) || Screen == 'Income Protection Price Setting')"
                                           :user-details="UserDetails" />
                    </div>

                    <div class="maxWidth">
                        <report-selection v-if="Selection !== null && Screen == 'Report Selection' && UserDetails !== null && (UserDetails.hasSetNoPricing || UserDetails.integrations?.length > 0)"
                                          v-on:comparison-selected="comparisonSelected()" v-on:datatable-selected="dataTableSelected()"
                                          v-bind:user-details="UserDetails" />
                    </div>

                    <div class="maxWidth">
                        <add-customer v-if="Screen === 'Add Customer'" v-on:back="back"
                                      v-on:save-customer="LoadPage('Tool Selection')" v-bind:user-details="UserDetails"
                                      v-bind:user-selection="Selection" />
                    </div>

                    <div class="maxWidth">
                        <customer v-if="Screen === 'Customer Selection'" @load-tool-selection="LoadPage('Tool Selection')"
                                  v-on:back="back" @add-customer="LoadPage('Add Customer')" @analysis-selected="loadAnalysis"
                                  @buy-more-credits-selected="LoadPage('Buy More Credits')"
                                  v-on:edit-customer="LoadPage('Add Customer')" v-bind:user-details="UserDetails"
                                  v-bind:user-selection="Selection" />
                    </div>

                    <div class="maxWidth">
                        <data-tables-templates v-if="Screen == 'Data Table Templates'" v-on:back="back"
                                               @skip-templates="skipTemplates" @template-selected="TemplateSelected"
                                               @select-no-template="NoTemplateSelected" v-bind:user-selection="Selection" />
                    </div>

                    <div class="maxWidth">
                        <comparisons-templates v-if="Screen == 'Comparison Templates'" v-on:back="back"
                                               v-on:backtopolicy-selected="BackToPolicy()" @skip-templates="skipTemplates"
                                               @template-selected="TemplateSelected" @select-no-template="NoTemplateSelected"
                                               v-bind:user-selection="Selection" v-bind:user-details="UserDetails" />
                    </div>

                    <div class="maxWidth"
                         v-if="Selection !== null && Selection.tool != null && !(['IPPro - Pricing', 'CIPlus - Pricing', 'LifePro - Pricing', 'MPCIPro - Pricing', 'MPPro - Pricing', 'FIBCIPro - Pricing', 'FIBPro - Pricing', 'KeyPersonCIPro - Pricing', 'KeyPersonPro - Pricing'].includes(Selection.tool.toolReference))">
                        <provider-selection :datatable-template="DataTableTemplate" v-if="Screen == 'Provider Selection'"
                                            v-on:back="back" v-on:provider-selection-completed="providerSelectionCompleted()"
                                            v-bind:user-selection="Selection" v-bind:user-details="UserDetails" />
                    </div>
                    <div class="maxWidth">
                        <income-protection-provider-selection v-if="Screen == 'Provider Selection' && Selection.tool.toolReference === 'IPPro - Pricing'"
                                                              v-on:back="back" v-on:provider-selection-completed="providerSelectionCompleted()"
                                                              v-bind:user-selection="Selection" v-bind:user-details="UserDetails" />
                    </div>
                    <div class="maxWidth">
                        <life-provider-selection :datatable-template="DataTableTemplate"
                                                 v-if="Screen == 'Provider Selection' && ['CIPlus - Pricing', 'LifePro - Pricing', 'FIBPro - Pricing', 'KeyPersonPro - Pricing'].includes(Selection.tool.toolReference)"
                                                 v-on:back="back" v-on:provider-selection-completed="providerSelectionCompleted()"
                                                 v-bind:user-selection="Selection" v-bind:user-details="UserDetails" />
                    </div>
                    <div class="maxWidth">
                        <mortgage-provider-selection :datatable-template="DataTableTemplate"
                                                     v-if="Screen == 'Provider Selection' && ['MPCIPro - Pricing', 'MPPro - Pricing', 'FIBCIPro - Pricing', 'KeyPersonCIPro - Pricing'].includes(Selection.tool.toolReference)"
                                                     v-on:back="back" v-on:provider-selection-completed="providerSelectionCompleted()"
                                                     v-bind:user-selection="Selection" v-bind:user-details="UserDetails" />
                    </div>
                    <!-- <div class="maxWidth">
                        <data-table-selection :datatable-template="DataTableTemplate"
                                              v-if="Screen == 'Data Table Selection'" :user-selection="Selection" v-on:back="back"
                                              @datatable-selection-completed="LoadDataTables" v-on:edit-customer="LoadPage('Add Customer')" v-bind:user-details="UserDetails" />
                    </div> -->
                    <!-- <div class="maxWidth">
                        <data-table-tool-selection :datatable-template="DataTableTemplate"
                                                   v-if="Screen == 'Data Table Tool Selection'" :user-selection="Selection" v-on:back="back"
                                                   @datatable-selection-completed="LoadDataTables" v-on:edit-customer="LoadPage('Add Customer')"
                                                   v-on:tool-selected="toolSelected" v-bind:user-details="UserDetails" />
                    </div> -->
                    <!-- <div>
                        <view-data-tables @full-refine="loadFullRefine"
                                          :ShouldDoFullRefine="ShouldDataTablesRefineRedirectToProvider"
                                          :datatable-template="DataTableTemplate" v-if="Screen == 'View Data Table'"
                                          :user-selection="Selection" v-on:back="back" />
                    </div> -->

                    <div class="maxWidth">
                        <income-protection-commission-setting v-if="Screen == 'Income Protection Commission Setting'"
                                                              v-on:back="back" v-bind:user-details="UserDetails" v-bind:user-selection="Selection" />
                    </div>
                    <div class="maxWidth">
                        <buy-more-credits v-if="Screen == 'Buy More Credits'" v-on:back="back"
                                          v-bind:user-details="UserDetails" v-bind:user-selection="Selection" />
                    </div>

                    <div class="maxWidth">
                        <terms-conditions v-if="Screen === 'Terms and Conditions' || showTerms" @terms-closed="back()"
                                          :read-only="!showTerms" document="terms" />
                        <terms-conditions v-else-if="Screen === 'Legal Agreement' || showLegal" @terms-closed="back()"
                                          :read-only="!showLegal" document="legal" />
                    </div>
                    <div class="maxWidth">
                        <privacy-policy v-if="Screen === 'Privacy Policy'" @privacypolicy-closed="back" />
                    </div>

                    <products-configuration v-if="Screen === 'Products Configuration'" />
                    <filters-configuration v-if="Screen === 'Filters Configuration'" />
                    <configure-pop-up-message v-if="Screen === 'PopUp Message Configuration'" v-on:back="back" />
                </div>
            </div>
        </el-main>
        <el-footer height="60px" style="padding: 0 0 0 0 ;">
            <footer-control v-if="UserDetails !== null" @call-policy="LoadPage('Privacy Policy')"
                            @call-terms="LoadPage('Terms and Conditions')" v-bind:user-details="UserDetails" />
        </el-footer>

    </el-container>
</template>
<style>
    .maxWidth:empty {
        display: none;
    }

    .el-footer {
        padding: 0 0 0 0;
    }

    .el-container {
        background-color: #E0E0E0;
    }
</style>

<script>
    import HeaderControl from './HeaderControl.vue';
    import PrivacyPolicy from "./PrivacyPolicy.vue";
    import TermsConditions from "./TermsConditions.vue";
    import LoadingControl from './LoadingControl.vue';
    import ReportSelection from './ReportSelection.vue';

    import ProviderSelection from "./ProviderSelection.vue";
    import IncomeProtectionProviderSelection from "./IncomeProtectionProviderSelection.vue";
    import LifeProviderSelection from "./LifeProviderSelection.vue";
    import MortgageProviderSelection from "./MortgageProviderSelection.vue";

    import FooterControl from "./Footer.vue";
    import $ from 'jquery';
    import helper from './HelperMethods.vue';
    //import DataTableSelection from "./DataTableSelection.vue";
    //import ViewDataTables from './ViewDataTables.vue';
    import DataTablesTemplates from "./DataTableTemplates.vue";
    import ComparisonsTemplates from "./ComparisonTemplates.vue";
    import IncomeProtectionCommissionSetting from "./IncomeProtectionCommissionSetting.vue";

    import ContactUs from './ContactUs.vue';
    import Customer from './Customer.vue';
    import AddCustomer from './AddCustomer.vue';
    import BuyMoreCredits from './BuyMoreCredits.vue';

    import UserMessage from './UserMessage.vue';
    import { ElMessage } from 'element-plus';

    //import DataTableToolSelection from './DataTableToolSelection.vue';
    import FindYourLegacyPolicy from "./FindYourLegacyPolicy.vue";
    import ProductsConfiguration from "./config/ProductsConfiguration.vue";
    import ConfigurePopUpMessage from "./config/ConfigurePopUpMessage.vue";
    import IntegrationModal from './pricing/IntegrationModal.vue';
    import FiltersConfiguration from './config/FiltersConfiguration.vue';
    import axios from 'axios';

    export default {
        name: 'NavigationControl',
        props: ["userDetails", "templateSet"],
        computed: {
            UserDetails: { get: function () { return this.userDetails; } },
            TemplateSet: { get: function () { return this.templateSet; } },
            showTerms: {
                get: function () {
                    return !this.UserDetails.hasAcceptedTermsAndCondiditions;
                }
            },
            showLegal: {
                get: function () {
                    return !this.UserDetails.hasAcceptedLegalAgreement;
                }
            }
        },
        data: function () {
            return {
                Adverts: null,
                Selection: null,
                Screen: "Report Selection",
                CurrentFeatureCategory: "",
                ScreenToGoBackTo: null,
                DataTableTemplate: null,
                ShouldDataTablesRefineRedirectToProvider: false,
                loadingToolConfig: false,
                isLoading: false,
                isShowUserPopup: true,
                popUpMessageData: {}
            };
        },
        components: {
            HeaderControl,
            LoadingControl,
            ReportSelection,

            ProviderSelection,

            FooterControl,
            //DataTableSelection,
            //ViewDataTables,
            DataTablesTemplates,
            ComparisonsTemplates,
            IncomeProtectionProviderSelection,

            IncomeProtectionCommissionSetting,
            LifeProviderSelection,
            MortgageProviderSelection,

            ContactUs,
            Customer,
            TermsConditions,
            PrivacyPolicy,
            AddCustomer,
            BuyMoreCredits,
            //DataTableToolSelection,

            FindYourLegacyPolicy,
            ProductsConfiguration,
            FiltersConfiguration,
            IntegrationModal,
            ConfigurePopUpMessage,
            UserMessage
        },
        mounted: async function () {
            var thisControl = this;

            //For showing Popup message
            try {
                thisControl.popUpMessageData = await thisControl.fetchPopupMessage();

                if (thisControl.popUpMessageData === null || thisControl.popUpMessageData === undefined) {
                    thisControl.isShowUserPopup = false;
                    sessionStorage.removeItem("LastSeenPopupTimestamp");
                    sessionStorage.removeItem("IsShowUserPopup");
                }
                else {
                    const lastSeenTimestamp = sessionStorage.getItem("LastSeenPopupTimestamp");

                    if (this.popUpMessageData.timestamp !== lastSeenTimestamp) {
                        this.isShowUserPopup = true;
                    } else {
                        this.isShowUserPopup = false;
                    }
                }
            } catch (error) {
                console.log('Error fetching config:', error);
            }

            var pageToLoad = "Report Selection";
            //if (!this.UserDetails.hasAcceptedTermsAndCondiditions)
            //    pageToLoad = "Terms and Conditions"
            var waiting = 2;
            var selection = null;
            var onDone = function () {
                thisControl.Selection = selection;
                var customerReference = thisControl.$route.params.customerReference;
                var analysisReference = thisControl.$route.params.analysisReferecnce;

                if (customerReference !== undefined && customerReference !== "" && analysisReference !== undefined && analysisReference !== "") {
                    thisControl.isLoading = true;
                    pageToLoad = "Analysis";
                    $.ajax({
                        type: 'GET',
                        url: '/CustomerAnalysis/' + customerReference + '/' + analysisReference,
                        contentType: 'application/json',
                        success: function (data) {
                            if (data.userSelection !== null && data.analysis.analysisReferecnce === undefined) {
                                data.analysis.analysisReferecnce = analysisReference;
                            }
                            thisControl.loadAnalysis(data.userSelection, data.analysis);
                            thisControl.isLoading = false;
                        },
                        error: function (xhr) {
                            if (xhr.status == 401) {
                                window.location = "./Api/signin";
                                thisControl.isLoading = false;
                                return;
                            }
                        }
                    });
                }
                else {
                    window.history.replaceState({ screen: pageToLoad, type: "screen" }, pageToLoad);
                }

                thisControl.Screen = pageToLoad;
                document.title = "Protection Guru Pro - " + thisControl.Screen;
            }
            $.ajax({
                type: 'GET',
                url: '/api/advert',
                contentType: 'application/json',
                success: function (Adverts) {
                    thisControl.Adverts = Adverts;
                    waiting--;
                    if (waiting == 0)
                        onDone();
                },
                error: function () {
                    //alert("Error Loading Protection Guru: " + xhr.responseText);
                }
            })
            $.ajax({
                type: 'GET',
                url: '/Api/config',
                contentType: 'application/json',
                success: function (Selection) {
                    selection = Selection;
                    waiting--;
                    if (waiting == 0)
                        onDone();
                },
                error: function (xhr) {
                    if (xhr.status == 401) {
                        window.location = "./Api/signin";
                        return;
                    }
                    //alert("Error Loading Protection Guru: " + xhr.responseText);
                }
            });
            window.addEventListener("popstate", function (event) {
                thisControl.goToPage(event.state);
            });
        },
        methods: {
            loadAnalysis: function (loadedUserSelection, returnedAnalysis) {
                console.log("Loading Analysis");
                console.log(loadedUserSelection);
                this.Selection = loadedUserSelection;
                this.Selection.PreAnalysis = returnedAnalysis;
                this.SwitchPage("Analysis");
            },
            loadFullRefine: function () {
                this.ShouldDataTablesRefineRedirectToProvider = false;
                this.SwitchPage("Provider Selection")
            },
            TemplateSelected: function (template) {
                if (this.Selection.analysisType !== "Comparison") {
                    this.Selection.selectedProviders = template.includedProviders;
                    this.Selection.includedFeatureInDataTable = template.includedFeatures;
                    this.ShouldDataTablesRefineRedirectToProvider = true;
                    this.DataTableTemplate = template;
                    this.LoadPage("View Data Table")
                }
                else {
                    //this.Selection = template.selection;
                    this.Selection.analysisType = template.selection.analysisType;
                    this.Selection.categoryWeightings = template.selection.categoryWeightings;
                    this.Selection.extendedFeatures = template.selection.extendedFeatures;
                    this.Selection.extendedFeaturesTool = template.selection.extendedFeaturesTool;
                    this.Selection.features = template.selection.features;
                    this.Selection.featuresTool = template.selection.featuresTool;
                    this.Selection.hasChildRequire = template.selection.hasChildRequire;
                    this.Selection.hasDataTables = template.selection.hasDataTables;
                    this.Selection.hasChildRequire = template.selection.hasChildRequire;
                    this.Selection.includeCategoryInDataTable = template.selection.includeCategoryInDataTable;
                    this.Selection.includedFeatureInDataTable = template.selection.includedFeatureInDataTable;
                    this.Selection.includedProviders = template.selection.includedProviders;
                    this.Selection.name = template.selection.name;
                    this.Selection.providers = template.selection.providers;
                    this.Selection.providersTool = template.selection.providersTool;
                    this.Selection.reportTitle = template.selection.reportTitle;
                    this.Selection.selectedProviders = template.selection.selectedProviders;
                    this.Selection.shouldIncludeDefinitions = template.selection.shouldIncludeDefinitions;
                    this.Selection.tool = template.selection.tool;
                    this.Selection.tools = template.selection.tools;
                    this.Selection.isTemplateSelected = true;

                    this.LoadPage("Analysis");
                }
            },
            NoTemplateSelected: function () {
                if (this.Selection.analysisType !== "Comparison")
                    this.DataTableTemplate = null;
                else {
                    if (this.Selection !== null && this.Selection !== undefined) {

                        //this.Selection.amountAssured = 0;
                        //this.Selection.policyTerm = 0;
                        //this.Selection.children = [];
                        //this.Selection.primaryPersonName = '';
                        //this.Selection.secondaryPersonName = '';
                        //this.Selection.childNames = [];
                        //this.Selection.primaryClient = { classification: "Male", dateOfBirth: '1990-01-01' };
                        //this.Selection.secondaryClient = null;

                        this.Selection.isTemplateSelected = false;
                        this.Selection.includedProviders = [];
                        this.Selection.shouldIncludeDefinitions = false;
                        this.Selection.includeFeatureInDataTable = [];
                        this.Selection.includeCategoryInDataTable = [];
                        this.Selection.selectedProviders = [];
                        this.Selection.hasDataTables = false;
                        this.Selection.categoryWeightings.filter(function (category) { category.weight = 0 });
                        this.Selection.extendedFeatures.filter(function (feature) { feature.weight = 0 });
                    }
                }
                this.LoadPage("Provider Selection")
            },
            getToolConfiguration: function (toolReference) {
                var that = this;
                that.loadingToolConfig = true;
                return axios.get(`api/Configuration/GetToolConfiguration/${toolReference}`)
                    .then(response => {
                        var { tool, features, extendedFeatures, providers } = response.data;
                        tool.toolReference = toolReference; // for backwards compatibillity
                        that.Selection.tool = tool;
                        that.Selection.features = features;
                        that.Selection.extendedFeatures = extendedFeatures;
                        that.Selection.providers = providers
                        that.Selection.selectedProviders = []; //clear providers
                        that.Selection.includedFeatureInDataTable = []; //Clear included features in DataTable;

                        if (this.Selection.extendedFeatures !== undefined && this.Selection.extendedFeatures !== null) {
                            this.Selection.categoryWeightings = this.Selection.extendedFeatures
                                .sort(function (ef1, ef2) { return ef1.featureCategoryOrder - ef2.featureCategoryOrder; })
                                .map(function (ef) { return ef.featureCategory; })
                                .filter(helper.distinct)
                                .map(function (c) { return { category: c, weight: 0, includedInDataTable: 0 } });
                        }
                    })
                    .then(() => {
                        that.loadingToolConfig = false;
                    });
            },
            toolUpdate: function (toolReference) {
                if (toolReference !== this.Selection.tool.toolReference && !this.loadingToolConfig) {
                    this.getToolConfiguration(toolReference);
                }
            },
            toolSelected: function (toolReference) {
                var that = this;
                this.getToolConfiguration(toolReference)
                    .then(function () {
                        if (that.Selection.analysisType === "DataTables") {
                            if (!that.userDetails.hasSetNoPricing)
                                that.Selection.tool = that.Selection.nonPricingTools.find(t => t.toolReference === toolReference);

                            that.LoadPage("Data Table Selection");
                        }
                        else
                            that.LoadPage("Policy Details");
                    });
            },
            dataTableSelected: function () {
                this.Selection.analysisType = "DataTables";
                this.Selection.selectedProviders = []; //clear providers in case the user went to datatables
                this.LoadPage("Data Table Tool Selection");
            },
            comparisonSelected: function () {
                this.DataTableTemplate = null;
                this.Selection.selectedProviders = []; //clear providers in case the user went to datatables
                this.Selection.analysisType = "Comparison";
                this.LoadPage("Customer Selection");
            },
            skipTemplates: function () {
                if (this.Selection.analysisType !== "Comparison") {
                    this.DataTableTemplate = null;
                }

                this.SwitchPage("Category Selection");
            },
            RefineSelection: function () {
                this.LoadPage("Policy Details");
            },
            policyDetailsAdded: function () {
                var thisControl = this;

                var ciTools = [
                    'CIPlus', 'CIPlus - Pricing',
                    'MPCIPro', 'MPCIPro - Pricing',
                    'KeyPersonCIPro - Pricing', 'KeyPersonCIPro',
                    'FIBCIPro - Pricing', 'FIBCIPro'
                ];

                if (ciTools.includes(thisControl.Selection.tool.toolReference)) {
                    this.LoadPage("Compare Critical Illness Policy");
                }
                else {
                    this.ciPolicyDetailsAdded();
                }
            },
            ciPolicyDetailsAdded: async function () {
                var thisControl = this;

                // log journey start if not tracked yet
                if (!thisControl.Selection.journeyReference) {
                    await $.ajax({
                        type: 'POST',
                        url: '/api/Journey/Start',
                        contentType: 'application/json',
                        data: JSON.stringify(thisControl.Selection),
                        success: function (data) {
                            thisControl.Selection.journeyReference = data;
                        },
                        error: function (e) {
                            thisControl.$message.error("Error:\n" + e.responseText);
                        }
                    });
                }
                if (this.TemplateSet !== null
                    && thisControl.TemplateSet.toolTemplates.filter(function (t) {
                        return t.toolKey === thisControl.Selection.tool.toolReference;
                    })) {
                    switch (thisControl.Selection.tool.toolReference) {
                        case "IPPro - Pricing":
                            this.LoadPage("Income Protection Plan Selection");
                            return;
                        case "MPPro - Pricing":
                        case "CIPlus - Pricing":
                        case "LifePro - Pricing":
                        case "MPCIPro - Pricing":
                            this.LoadPage("Term Assurance Plan Selection");
                            return;
                        case "KeyPersonCIPro - Pricing":
                        case "KeyPersonPro - Pricing":
                            this.LoadPage("Business Protection Plan Selection");
                            return;
                        case "FIBPro - Pricing":
                        case "FIBCIPro - Pricing":
                            this.LoadPage("Family Income Benefit Plan Selection");
                            return;
                        case "FIBPro":
                        case "KeyPersonPro":
                        case "FIBCIPro":
                        case "KeyPersonCIPro":
                        case "IPPro":
                        case "MPPro":
                        case "CIPlus":
                        case "LifePro":
                        case "MPCIPro":
                            this.LoadPage("Express Question");
                            return;
                    }
                }
                this.LoadPage("Comparison Templates");
            },
            expressQuestionSelected: function () {
                this.LoadPage("Comparison Templates");
            },
            expressQuestionWithoutPriceSelected: function () {
                this.LoadPage("Express Question Without Price");
            },
            underwritingAnalysis: function () {
                this.LoadPage("Underwriting Analysis");
            },
            infoVerification: function () {
                if (this.Screen != "Underwriting Analysis" && this.UserDetails.defaultIntegration === "UNDERWRITEME" && this.Selection.tool.toolReference !== "KeyPersonPro - Pricing")
                    this.underwritingAnalysis();
                else
                    this.LoadPage("Info Verification");
            },
            ipExpressQuestionSelected: function () {
                this.LoadPage("Income Protection Express Question");
            },
            taExpressQuestionSelected: function () {
                this.LoadPage("Term Assurance Express Question");
            },
            fibExpressQuestionSelected: function () {
                this.LoadPage("Family Income Benefit Express Question");
            },
            bpCompareCriticalIllnessPolicy: function () {
                this.LoadPage("Compare Critical Illness Policy");
            },
            bpExpressQuestionSelected: function () {
                this.LoadPage("Business Protection Express Question");
            },
            compareRouterScreens: function (screen) {
                this.Screen = screen;
                var routerScreens = ['Tool Selection', 'Policy Details',
                    'Info Verification', 'Express Question', 'Income Protection Plan Selection', 'Term Assurance Plan Selection',
                    'Family Income Benefit Plan Selection', 'Business Protection Plan Selection', 'Express Question Without Price',
                    'Income Protection Express Question', 'Term Assurance Express Question', 'Family Income Benefit Express Question',
                    'Business Protection Express Question', 'Category Selection', 'Compare Critical Illness Policy', 'Feature Selection',
                    'Analysis', 'Underwriting Analysis', 'Customer Selection', 'Report Selection', 'Data Table Tool Selection', 'Data Table Selection', 'View Data Table'];

                return routerScreens.indexOf(this.Screen) >= 0;
            },
            back: function () {
                var backURL = this.$router.options.history.state.back;
                if (backURL !== "/" && backURL !== "" && backURL !== null) {
                    if (backURL === "/InfoVerification") {
                        this.Screen = "Info Verification";
                    } else if (backURL === "/PolicyDetails") {
                        this.Screen = "Policy Details";
                    } else if (backURL === "/ExpressQuestion") {
                        this.Screen = "Express Question";
                    } else if (backURL === "/CategorySelection") {
                        this.Screen = "Category Selection";
                    } else if (backURL === "/FeatureSelection") {
                        this.Screen = "Feature Selection"
                    } else if (backURL === "/TemplateSelection") {
                        this.Screen = "Compare Critical Illness Policy";
                    } else if (backURL === "/AdditionalInfo") {
                        this.Screen = "Express Question Without Price";
                    } else if (backURL === undefined) {
                        this.Screen = "Analysis";
                    } else if (backURL.indexOf("Analysis") > 0) {
                        this.Screen = "Analysis";
                    }
                } else {
                    if (this.Screen == "Tool Selection") {
                        this.Screen = "Report Selection";
                    }
                    else if (this.Screen == "Policy Details") {
                        this.Screen = "Tool Selection";
                    }
                    else if ((this.Selection.showCIPolicy == "No" || this.Selection.showCIPolicy == "Yes") && (this.Screen == "Term Assurance Plan Selection" || this.Screen == "Family Income Benefit Plan Selection"
                        || this.Screen == "Business Protection Plan Selection" || this.Screen == "Compare Critical Illness Policy")) {
                        this.Screen = "Compare Critical Illness Policy";
                    }
                    else if (this.Screen == "Express Question" || this.Screen == "Income Protection Plan Selection" || this.Screen == "Term Assurance Plan Selection"
                        || this.Screen == "Family Income Benefit Plan Selection" || this.Screen == "Business Protection Plan Selection"
                        || this.Screen == "Compare Critical Illness Policy") {
                        this.Screen = "Policy Details";
                    }
                    else if (this.Screen == "Express Question Without Price" || this.Screen == "Category Selection"
                        || this.Screen == "Income Protection Express Question" || this.Screen == "Term Assurance Express Question"
                        || this.Screen == "Family Income Benefit Express Question" || this.Screen == "Business Protection Express Question") {
                        this.Screen = "Express Question";
                    }
                    else if (this.Screen == "Feature Selection") {
                        this.Screen = "Category Selection";
                    }
                    else if (this.Screen == "Underwriting Analysis") {
                        if (this.UserDetails.defaultIntegration === "UNDERWRITEME") {
                            this.Screen = "Underwriting Analysis"
                        } else {
                            if (this.Selection.planType === "Express") {
                                this.Screen = "Express Question Without Price";
                            } else {
                                this.Screen = "Feature Selection";
                            }
                        }
                    }
                    else if (this.Screen == "Info Verification") {
                        if (this.Selection.planType === "Express") {
                            this.Screen = "Express Question Without Price";
                        } else {
                            this.Screen = "Feature Selection";
                        }
                    }
                    else if (this.Screen == "Analysis") {
                        this.Screen = "Info Verification";
                    }
                }
                this.$router.go(-1);
                /*window.history.back();*/
            },
            LoadPage: function (screen) {
                this.Screen = screen;
                if (screen == "Tool Selection") {
                    this.$router.push({ name: "ToolSelectionPage", path: '/ToolSelection' });
                }
                else if (screen == "Policy Details") {
                    this.$router.push({ name: "PolicyDetailsPage", path: '/PolicyDetails' });
                }
                else if (screen == "Info Verification") {
                    this.$router.push({ name: "InfoVerificationPage", path: '/InfoVerification' });
                }
                else if (screen == "Express Question" || screen == "Income Protection Plan Selection" || screen == "Term Assurance Plan Selection"
                    || screen == "Family Income Benefit Plan Selection" || screen == "Business Protection Plan Selection") {
                    this.$router.push({ name: "ExpressQuestionPage", path: '/ExpressQuestion' });
                }
                else if (screen == "Express Question Without Price" || screen == "Income Protection Express Question" || screen == "Term Assurance Express Question"
                    || screen == "Family Income Benefit Express Question" || screen == "Business Protection Express Question") {
                    this.$router.push({ name: "ExpressQuestionsWithoutPricePage", path: '/AdditionalInfo' });
                }
                else if (screen == "Category Selection") {
                    this.$router.push({ name: "CategorySelectionPage", path: '/CategorySelection' });
                }
                else if (screen == "Compare Critical Illness Policy") {
                    this.$router.push({ name: "TemplateSelectionPage", path: '/TemplateSelection' });
                }
                else if (screen == "Feature Selection") {
                    this.$router.push({ name: "FeatureSelectionPage", path: '/FeatureSelection' });
                }
                else if (screen == "Analysis") {
                    this.$router.push({ name: "AnalysisPage", path: '/Analysis', params: { customerReference: this.Selection.customerReference, analysisReferecnce: '' } });
                }
                else if (screen == "Underwriting Analysis") {
                    this.$router.push({ name: "UnderwritingAnalysisPage", path: '/UnderwritingAnalysis' });
                }
                else if (screen == "Customer Selection" && this.UserDetails.shouldShowInitialPricingQuestion) {
                    this.Screen = "Income Protection Price Setting";
                }
                else if (screen == "Comparison Templates") {
                    this.$router.push({ name: "CategorySelectionPage", path: '/CategorySelection' });
                }
                else if (screen == "Data Table Tool Selection") {
                    this.$router.push({ name: "DataTableToolSelection", path: '/DataTableToolSelection' });
                }
                else if (screen == "Data Table Selection") {
                    this.$router.push({ name: "DataTableSelection", path: '/DataTableSelection' });
                }
                else if (screen == "View Data Table") {
                    this.$router.push({ name: "ViewDataTables", path: '/ViewDataTables' });
                }
                else {
                    window.history.pushState({ screen: screen, type: "screen" }, screen);
                }
                document.title = "Protection Guru Pro - " + screen;
            },
            SwitchPage: function (screen) {
                this.Screen = screen;

                if (screen == "Analysis") {
                    var anaRef = "";
                    if (this.Selection.PreAnalysis !== undefined && this.Selection.PreAnalysis.analysisReferecnce !== undefined && this.Selection.PreAnalysis.analysisReferecnce !== null) {
                        anaRef = this.Selection.PreAnalysis.analysisReferecnce;
                    }
                    this.$router.push({ name: "AnalysisPage", path: '/Analysis', params: { customerReference: this.Selection.customerReference, analysisReferecnce: anaRef } });
                }
                else if (screen == "Category Selection") {
                    this.$router.replace({ name: "CategorySelectionPage", path: '/CategorySelection' });
                }
                else if (screen == "Feature Selection") {
                    this.$router.replace({ name: "FeatureSelectionPage", path: '/FeatureSelection' });
                }
                else if (screen == "Info Verification") {
                    this.$router.replace({ name: "InfoVerificationPage", path: '/InfoVerification' });
                }
                else {
                    window.history.replaceState({ screen: screen, type: "screen" }, screen);
                }
                document.title = "Protection Guru Pro - " + screen;
            },
            LoadCategoryFeatures: function (featureCategory, replaceScreen) {
                this.Screen = "Feature Selection";
                this.CurrentFeatureCategory = featureCategory;
                if (replaceScreen) {
                    this.$router.replace({ path: '/FeatureSelection', featureCategory: featureCategory, type: "featureCategory" });
                }
                else {
                    this.$router.push({ path: '/FeatureSelection', featureCategory: featureCategory, type: "featureCategory" });
                }
                document.title = "Protection Guru Pro - " + featureCategory + " Features";
            },
            goToPage: function (screendetails) {
                if (this.ScreenToGoBackTo !== null) {
                    if (screendetails.type === "screen" && screendetails.screen === this.ScreenToGoBackTo) {
                        this.ScreenToGoBackTo = null;
                    }
                    else {
                        this.back();
                    }
                }
                switch (screendetails.type) {
                    case "screen":
                        this.Screen = screendetails.screen;
                        document.title = "Protection Guru Pro - " + this.Screen;
                        break;
                    case "featureCategory":
                        this.Screen = "Feature Selection";
                        this.CurrentFeatureCategory = screendetails.featureCategory
                        document.title = "Protection Guru Pro - " + this.CurrentFeatureCategory + " Features";
                        break;
                }
            },
            providerSelectionCompleted: function () {
                if (this.Selection.analysisType === 'Comparison')
                    this.LoadPage("Category Selection");
                else
                    this.LoadPage("Data Table Selection");
            },
            categorySelectionCompleted: function () {
                var validCategories = this.Selection.categoryWeightings.filter(this.Selection.analysisType === "Comparison" ? function (cw) { return cw.weight > 0; } : function (cw) { return cw.includedInDataTable; });
                this.LoadCategoryFeatures(validCategories[0].category, false);
            },
            categorySelectionSkipped: function () {
                var validCategories = this.Selection.categoryWeightings.filter(this.Selection.analysisType === "Comparison" ? function (cw) { return cw.weight > 0; } : function (cw) { return cw.includedInDataTable; });
                this.LoadCategoryFeatures(validCategories[0].category, true);
            },
            featureSelectionCompleted: function () {
                var validCategories = this.Selection.categoryWeightings.filter(this.Selection.analysisType === "Comparison" ? function (cw) { return cw.weight > 0; } : function (cw) { return cw.includedInDataTable; }).map(function (cw) { return cw.category; });
                var currentFeatureIndex = validCategories.indexOf(this.CurrentFeatureCategory);
                if (currentFeatureIndex < validCategories.length - 1)
                    this.LoadCategoryFeatures(validCategories[currentFeatureIndex + 1], false);
                else {
                    if (this.Selection.planType === "Custom")
                        this.infoVerification();
                    else
                        this.LoadPage("Analysis");
                }
            },
            featureSelectionSkipped: function () {
                console.log("Skipping " + this.CurrentFeatureCategory);
                var validCategories = this.Selection.categoryWeightings.filter(this.Selection.analysisType === "Comparison" ? function (cw) {
                    return cw.weight > 0;
                } : function (cw) { return cw.includedInDataTable; }).map(function (cw) {
                    return cw.category;
                });
                var currentFeatureIndex = validCategories.indexOf(this.CurrentFeatureCategory);
                if (currentFeatureIndex < validCategories.length - 1)
                    this.LoadCategoryFeatures(validCategories[currentFeatureIndex + 1], true);
                else {
                    var thisControl = this;
                    if (thisControl.Selection.goToUnderWriteMe === true) {
                        thisControl.isRedirecting = true;

                        $.ajax({
                            type: 'POST',
                            url: "/Api/",
                            contentType: 'application/json',
                            data: JSON.stringify(thisControl.Selection),
                            success: function (data) {
                                window.open(data.url, '_blank').focus();
                                thisControl.isRedirecting = false;
                                thisControl.Selection.analysisReference = data.analysisReference;
                                thisControl.BackToScreen('Report Selection');
                                thisControl.BackToScreen('Report Selection');
                            },
                            error: function () {
                                thisControl.isRedirecting = false;
                                thisControl.$message.error("We could not create the UnderWrite me application.");
                                thisControl.BackToScreen('Report Selection');
                            }
                        });
                    }
                    else {
                        console.log("Skipping to Analysis");
                        thisControl.SwitchPage("Analysis");
                    }
                }
            },
            LoadDataTables: function () {
                this.LoadPage("View Data Table");
            },
            IsLast: function () {
                if (this.Selection !== null && this.Selection.categoryWeightings !== null) {
                    var validCategories = this.Selection.categoryWeightings.filter(this.Selection.analysisType === "Comparison" ? function (cw) { return cw.weight > 0; } : function (cw) { return cw.includedInDataTable; }).map(function (cw) { return cw.category; });
                    return validCategories[validCategories.length - 1] === this.CurrentFeatureCategory
                }
                return null;
            },
            BackToPolicy: function () {
                this.BackToScreen("Policy Details");
            },
            BackToScreen: function (screen) {
                if (this.Screen === screen)
                    return;
                this.ScreenToGoBackTo = screen;
                if (screen == "Report Selection") {
                    window.location.href = "/";
                }
                else {
                    this.$router.go(-1);
                }
                //window.history.back();
            },
            CommissionSettingSelected: function () {
                this.LoadPage("Income Protection Commission Setting");
            },
            AmendQualityAnalysis: function () {
                this.Selection.expressTemplate = null;
                this.LoadPage("Category Selection");
            },
            ContactUsSelected: function () {
                this.LoadPage("Contact Us");
            },
            PopupMessageSelected: function () {
                this.LoadPage("PopUp Message Configuration");
            },
            FindYourLegacyPolicySelected: function () {
                this.LoadPage("Find Your Legacy Policy");
            },
            customerSelected: function () {
                this.DataTableTemplate = null;
                this.Selection.selectedProviders = []; //clear providers in case the user went to datatables
                this.LoadPage("Tool Selection");
            },
            AnalysisSelected: function (UserSelection) {
                this.Selection = UserSelection;
            },
            async fetchPopupMessage() {
                try {
                    const response = await axios.get(`/api/PopUpMessages/getPopupMessage`);
                    if (response.data.success) {
                        return response.data.data;
                    }
                } catch (error) {
                    console.log('Error:', error);
                    ElMessage({
                        message: "An error occurred while retrieving the message",
                        type: 'error'
                    });
                    return null;
                }
            }
        },
    }
</script>
